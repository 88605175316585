'use client';
import React from 'react';
import Image from 'next/image';
import { useCarousel } from '@dr-pam/common-components/Hooks/useCarousel';

export default function MiniPrograms() {
	const { carouselElRef, itemRefs, scrollToNext, scrollToPrevious } = useCarousel({
		items: miniProgramBlocks,
	});

	return (
		<div className="mini-programs">
			<h2>Mini-programs included with the above:</h2>

			<div ref={carouselElRef} className="mini-programs-grid">
				{miniProgramBlocks.map((block, index) => (
					<div key={index} className="mini-program-card" ref={(ref) => (itemRefs.current[index] = ref)}>
						<div className="image-container">
							<Image
								src={block.image}
								alt={block.alt}
								width={300}
								height={200}
								priority={true}
								className="program-image"
							/>
						</div>
						<div className="program-icon">
							<Image
								src={block.icon}
								width={50}
								height={50}
								alt="Icon"
								className="program-icon-image"
								priority={true}
							/>
						</div>
						<h3 dangerouslySetInnerHTML={{ __html: block.title }}></h3>
					</div>
				))}
			</div>

			<div className="carousel-controls mobile-only">
				<button className="carousel-button prev-btn" onClick={scrollToPrevious} aria-label="Previous Research">
					<Image
						src="/images/icons/ArrowLine_Left.svg"
						alt="arrow left"
						width={80}
						height={80}
						priority={false}
						className="arrow-left"
					/>
				</button>
				<button className="carousel-button next-btn" onClick={scrollToNext} aria-label="Next Research">
					<Image
						src="/images/icons/ArrowLine_Right.svg"
						alt="arrow right"
						width={80}
						height={80}
						priority={false}
						className="arrow-right"
					/>
				</button>
			</div>
		</div>
	);
}

const miniProgramBlocks = [
	{
		image: '/images/accent-photos/baby-comes-home.avif',
		alt: 'Parents holding baby',
		icon: '/images/icons/Icon-MiniProgram_BabyComesHome.svg',
		title: 'When baby comes home',
	},
	{
		image: '/images/accent-photos/baby-sleep.webp',
		alt: 'Mother holding a sleeping baby',
		icon: '/images/icons/Icon_MiniPrograms_Sleep.svg',
		title: 'Baby sleep safety',
	},
	{
		image: '/images/accent-photos/sensory.avif',
		alt: 'Mother and baby hands touching sand',
		icon: '/images/icons/Icon_MiniPrograms_SensoryMotor.svg',
		title: 'Sensory motor development',
	},
	{
		image: '/images/accent-photos/baby-crying.jpg',
		alt: 'Baby crying',
		icon: '/images/icons/Icon_MiniPrograms_CriesFrequently.svg',
		title: 'When baby cries a lot in the first few months',
	},
	{
		image: '/images/accent-photos/parents-well-being.avif',
		alt: 'Parents well being',
		icon: '/images/icons/Icon_MiniPrograms_ParentWellbeing.svg',
		title: 'Parent mental health and wellbeing',
	},
	{
		image: '/images/accent-photos/baby-eating.avif',
		alt: 'Food and baby in high chair',
		icon: '/images/icons/Icon_MiniPrograms_Food.svg',
		title: 'Little people <br /> + food',
	},
];
